<template>
	<div class="bordered">
		<Title> 家庭签约医生 </Title>
		<LabelValueGrid class="desc" :data="dataByLevel"></LabelValueGrid>
		<BaseEchart :option="option" style="width: 100%; height: 303px"></BaseEchart>
	</div>
</template>
<script>
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData';
import { random } from '@/utils';
import Title from '../components/Title';
import { barChart } from '../common';
import LabelValueGrid from '../components/LabelValueGrid.vue';
import { mapState } from 'vuex';
import { randomByLevel } from '@/views/homePage/common';
export default {
	components: { Title, LabelValueGrid, BaseEchart },
	data() {
		return {
      option: undefined,
			datas: [
				{ label: '服务总数', value: 1041 },
				{ label: '医生团队总数', value: 3125 },
				{ label: '医护人员总数', value: 1052 },
				{ label: '覆盖人群总数', value: 1104 },
				{ label: '覆盖家庭总数', value: 2068 },
			],
		};
	},
	mounted() {
		this.initChart();
	},
	watch:{
		mapDataArr:{
			handler(){
				this.initChart()
			}
		}
	},
	computed:{
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		dataByLevel(){
			return this.datas.map(item=>{
				return{
					...item,
					value:item.value/(this.mapLevel+1)
				}
				})


		}
	},
	methods: {
		initChart() {
			const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
			this.option = barChart({ xData: dataX.map((d) => d.name), yData: dataX.map((d) => d.value) });
		},
	},
};
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.desc {
  padding: 16px 16px 0 16px;
}
</style>
