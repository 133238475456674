<template>
	<div class="bordered wrapper h100">
		<Title>检查检验</Title>
		<div class="container">
			<div class="cardContainer">
				<Card name="疾病类型" :value="diseasetype"></Card>
				<Card name="检查检验图片数" :value="checkpicture"></Card>
			</div>
			<div class="barContainer">
				<div class="bar" :key="b.label" v-for="(b, index) in barsByLevel">
					<div class="indicator mr6" :style="{background: b.color}">{{ index+1 }}</div>
					<div class="label mr8">{{ b.label }}</div>
          <ProgressBar :percent="(b.value)/100" :color="b.color"></ProgressBar>
          <div class="text ml16">{{ b.value  + '%' }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Title from '../components/Title';
import Card from '../components/StatisticsCard.vue';
import ProgressBar from '../components/ProgressBar.vue'
import { mapState } from 'vuex';
import { random } from '@/utils';
export default {
	components: { Title, Card, ProgressBar },
	data() {
		return {
			bars: [
				{ label: '心脏病', value: 90, color: '#E5A959' },
				{ label: '高血压', value: 85, color: '#298071' },
				{ label: '糖尿病', value:72, color: '#4045C3' },
				{ label: '冠心病', value: 68, color: '#227093' },
				{ label: '心肌炎', value: 50, color: '#16416E' },
			],
      value1:159,
      value2:369,
		};
	},
  computed:{
    ...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    diseasetype(){
      return this.value1/(this.mapLevel+1)
    },
    checkpicture(){
      return this.value2/(this.mapLevel+1)
    },
    barsByLevel(){
      return this.bars.map(item=>{
        return {
          ...item,
          value:item.value-(random(0,10)*(this.mapLevel+1))
        }
      })
    }
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.container {
	display: flex;
  flex: 1;
}

.cardContainer {
  padding: 16px;
	display: flex;
	flex-direction: column;
  .card:nth-of-type(1) {
    margin-bottom: 16px;
  }
}
.barContainer {
  height: 100%;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 16px;
  .bar {
    display: flex;
    align-items: center;
    .indicator {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
    }
    .label {
      flex-shrink: 0;
    }
    .text {
      color: white;
      font-size: 18px;
    }
  }
}
</style>
